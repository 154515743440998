// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-check-up-js": () => import("./../src/pages/check-up.js" /* webpackChunkName: "component---src-pages-check-up-js" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-demo-booking-index-jsx": () => import("./../src/pages/demo-booking/index.jsx" /* webpackChunkName: "component---src-pages-demo-booking-index-jsx" */),
  "component---src-pages-demo-booking-thanks-jsx": () => import("./../src/pages/demo-booking/thanks.jsx" /* webpackChunkName: "component---src-pages-demo-booking-thanks-jsx" */),
  "component---src-pages-discover-builders-jsx": () => import("./../src/pages/discover/builders.jsx" /* webpackChunkName: "component---src-pages-discover-builders-jsx" */),
  "component---src-pages-discover-compliance-jsx": () => import("./../src/pages/discover/compliance.jsx" /* webpackChunkName: "component---src-pages-discover-compliance-jsx" */),
  "component---src-pages-discover-index-jsx": () => import("./../src/pages/discover/index.jsx" /* webpackChunkName: "component---src-pages-discover-index-jsx" */),
  "component---src-pages-discover-siteworkers-jsx": () => import("./../src/pages/discover/siteworkers.jsx" /* webpackChunkName: "component---src-pages-discover-siteworkers-jsx" */),
  "component---src-pages-discover-subcontractors-jsx": () => import("./../src/pages/discover/subcontractors.jsx" /* webpackChunkName: "component---src-pages-discover-subcontractors-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookies-js": () => import("./../src/pages/legal/cookies.js" /* webpackChunkName: "component---src-pages-legal-cookies-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-up-js": () => import("./../src/pages/project-up.js" /* webpackChunkName: "component---src-pages-project-up-js" */),
  "component---src-pages-service-up-js": () => import("./../src/pages/service-up.js" /* webpackChunkName: "component---src-pages-service-up-js" */),
  "component---src-pages-trade-up-js": () => import("./../src/pages/trade-up.js" /* webpackChunkName: "component---src-pages-trade-up-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-up-js": () => import("./../src/pages/work-up.js" /* webpackChunkName: "component---src-pages-work-up-js" */)
}

